<template>
  <div>
    <CRow>
      <CCol md="4">
        <CInput
          placeholder="Filter dengan Nomor HP"
          type="text"
          v-model="fastTrxCustomerNo"
          autocomplete="off"
          autofocus
        >
          <template #append>
            <CButton color="warning" @click="fastTrxCustomerNo = ''">
              <CIcon name="cil-trash" />
            </CButton>
          </template>
        </CInput>

        <CCard>
          <CCardHeader> Prabayar </CCardHeader>
          <CCardBody>
            <CTabs variant="pills" vertical>
              <div v-for="(value, name, index) in dataPrepaid" :key="index">
                <CTab>
                  <template slot="title">
                    <div
                      @click="startTrxPrepaid(value)"
                      class="d-flex justify-content-between align-items-center"
                    >
                      <span>
                        {{ name }}
                      </span>
                      <!-- <CBadge color="primary" size="sm">
                        {{ value.length }}
                      </CBadge> -->
                    </div>
                  </template>
                  <CListGroup
                    v-for="(value, name, index) in dataStartTrxPrepaid"
                    :key="index"
                  >
                    <CListGroupItem
                      component="a"
                      href="#"
                      class="d-flex justify-content-between align-items-center"
                      @click="startTrxPrepaidFInalData(value)"
                      :active="trxPrepaidFInalDataSelected.brand == name"
                    >
                      {{ name }}
                      <CBadge color="primary" size="sm">
                        {{ value.length }}
                      </CBadge>
                    </CListGroupItem>
                  </CListGroup>
                </CTab>
              </div>
            </CTabs>
          </CCardBody>
        </CCard>

        <!-- <CCard>
          <CCardHeader> Pascabayar </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol sm="12" lg="6"> </CCol>
            </CRow>
          </CCardBody>
        </CCard>-->
      </CCol>

      <CCol md="8">
        <CCard>
          <CCardHeader> Transaksi Prabayar </CCardHeader>
          <CCardBody>
            <CTabs variant="pills" vertical>
              <div
                v-for="(value, name, index) in dataListPrepaidProduct"
                :key="index"
              >
                <CTab>
                  <template slot="title">
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <span>
                        {{ name }}
                      </span>
                      <CBadge color="primary" size="sm">
                        {{ value.length }}
                      </CBadge>
                    </div>
                  </template>
                  <CListGroup v-for="(val, name, index) in value" :key="index">
                    <!-- @click="startTrxPrepaidFInalData(value)" -->
                    <CListGroupItem
                      component="a"
                      href="#"
                      :color="
                        val.pruduct_active && val.status ? 'success' : 'danger'
                      "
                      @click="buyPrepaid(val)"
                    >
                      <strong>
                        {{ val.price | currency }}
                      </strong>
                      -
                      {{ val.sku }}
                      -

                      <CBadge v-if="val.multi" color="primary">
                        <CIcon name="cil-loop"></CIcon>
                      </CBadge>
                      <CBadge v-else color="warning">
                        <CIcon name="cil-loop-1"></CIcon>
                      </CBadge>
                      <br />
                      <strong>{{ val.product_name }}</strong>
                      <br />
                      <small>{{ val.desc }}</small>
                    </CListGroupItem>
                  </CListGroup>
                </CTab>
              </div>
            </CTabs>
          </CCardBody>
        </CCard>
        <!-- <CCard>
          <CCardHeader> Transaksi Pascabayar </CCardHeader>
          <CCardBody> </CCardBody>
        </CCard> -->
      </CCol>
    </CRow>

    <!-- <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader> Transaksi Terakhir</CCardHeader>
          <CCardBody> </CCardBody>
        </CCard>
      </CCol>
    </CRow> -->

    <CRow>
      <CCol md="6">
        <Support />
      </CCol>
    </CRow>

    <CModal
      alignment="center"
      :title="dataBuyPrepaid.product_name"
      :closeOnBackdrop="false"
      :show.sync="modalBuyPrepaid"
    >
      <h4>
        <strong>{{ dataBuyPrepaid.price | currency }}</strong>
      </h4>
      <small> {{ dataBuyPrepaid.desc }}</small>
      <hr />

      <CForm
        @submit.prevent="submitPrepaidTrx()"
        class="mt-2"
        autocomplete="off"
        validated
        novalidate
      >
        <CInput
          placeholder="Nomor HP / No Customer"
          type="text"
          v-model="dataBuyPrepaid.customer_no"
          autocomplete="off"
          autofocus
          :invalid-feedback="validator.customer_no"
          :is-valid="validator.customer_no ? false : true"
        >
          <template #prepend-content><CIcon name="cil-stream" /></template>
          <template #append>
            <CButton color="warning" @click="dataBuyPrepaid.customer_no = ''">
              <CIcon name="cil-trash" />
            </CButton>
          </template>
        </CInput>
        <CInput
          placeholder="PIN Transaksi"
          type="number"
          v-model="dataBuyPrepaid.pin"
          autocomplete="off"
          :invalid-feedback="validator.pin"
          :is-valid="validator.pin ? false : true"
        >
          <template #prepend-content><CIcon name="cil-lock-locked" /></template>
        </CInput>
        <CRow>
          <CCol class="text-right">
            <CButton
              @click="submitPrepaidTrx()"
              color="success"
              variant="outline"
              :disabled="buyPrepaidProcessing"
              invalid-feedback="Please provide a required input."
            >
              <CIcon name="cil-reload" v-if="buyPrepaidProcessing" />
              Proses
            </CButton>
          </CCol>
        </CRow>
      </CForm>

      <CAlert
        :show="Object.keys(last_trx).length === 0 ? false : true"
        :color="translateStatus(last_trx.status)"
        class="mt-3"
      >
        <p>
          {{ last_trx.message }}
        </p>
        <hr />
        <p class="mb-0">Customer No: {{ last_trx.customer_no }}</p>
        <p class="mb-0">Serial: {{ last_trx.serial }}</p>
      </CAlert>

      <template #footer>
        <CButton
          @click="modalBuyPrepaid = false"
          color="danger"
          variant="outline"
        >
          Tutup
        </CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import Support from "../views/additional/Support.vue";
import axios from "../apis/api";
export default {
  name: "Dashboard",
  components: { Support },
  data() {
    return {
      fastTrxCustomerNo: "",
      fastTrxBrandName: "",
      dataPrepaid: {},
      dataStartTrxPrepaid: [],
      trxPrepaidFInalData: [],
      trxPrepaidFInalDataSelected: {},
      dataListPrepaidProduct: [],
      dataBuyPrepaid: {},
      buyPrepaidProcessing: false,
      validator: {
        pin: "",
        customer_no: "",
      },

      modalBuyPrepaid: false,
    };
  },
  computed: {
    last_trx() {
      return this.$store.state.last_trx;
    },
  },
  methods: {
    translateStatus(status) {
      let stat = "danger";
      switch (status) {
        case "failed":
          stat = "danger";
          break;
        case "pending":
          stat = "warning";
          break;
        case "success":
          stat = "success";
          break;

        default:
          stat = "danger";
          break;
      }
      return stat;
    },
    async submitPrepaidTrx() {
      if (this.buyPrepaidProcessing) {
        await this.$notify({
          type: "warn",
          text: "Masih ada transaksi yang sedang diproses",
        });
        return;
      }
      this.resetValidator();
      this.$store.state.last_trx = {};
      this.buyPrepaidProcessing = true;
      let dataTrx = {
        customer_no: this.dataBuyPrepaid.customer_no,
        pin: this.dataBuyPrepaid.pin,
        sku: this.dataBuyPrepaid.sku,
      };
      try {
        await axios.post(`/transaction/prepaid`, dataTrx);
      } catch (error) {
        if (error.response.data) {
          const errors = error.response.data.errors;
          if (errors.pin) {
            this.validator.pin = errors.pin.join(" & ");
          }
          if (errors.customer_no) {
            this.validator.customer_no = errors.customer_no.join(" & ");
          }
          if (error.response.data.message) {
            await this.$notify({
              type: "error",
              text: error.response.data.message,
            });
          }
        }
      }
      this.buyPrepaidProcessing = false;
    },
    resetValidator() {
      this.validator.pin = "";
      this.validator.customer_no = "";
    },
    async loadPrepaid() {
      const response = await axios.get("/price_list/get_dashboard", {
        params: {
          type: "prepaid",
          brand: this.fastTrxBrandName,
        },
      });

      const data = response.data.data;
      const groupBy = (array, key) => {
        return array.reduce((result, currentValue) => {
          (result[currentValue[key]] = result[currentValue[key]] || []).push(
            currentValue
          );
          return result;
        }, {});
      };
      this.dataPrepaid = groupBy(data, "category");
      this.startTrxPrepaid(this.dataPrepaid[Object.keys(this.dataPrepaid)[0]]);
    },
    async loadItemPrepaid(data) {
      let loader = await this.$loading.show();

      const category = data[0].category;
      const brand = data[0].brand;
      const response = await axios.get("/price_list/get_item_prepaid", {
        params: {
          category: category,
          brand: brand,
        },
      });
      const dataItem = response.data.data;
      const groupBy = (array, key) => {
        return array.reduce((result, currentValue) => {
          (result[currentValue[key]] = result[currentValue[key]] || []).push(
            currentValue
          );
          return result;
        }, {});
      };

      this.dataListPrepaidProduct = groupBy(dataItem, "type");
      loader.hide();
    },
    async buyPrepaid(data) {
      if (!data.pruduct_active || !data.status) {
        await this.$notify({
          type: "error",
          text: "Produk tidak aktif",
        });
        return;
      }
      this.$store.commit("set", ["last_trx", {}]);

      this.modalBuyPrepaid = true;
      if (this.fastTrxCustomerNo.length > 0) {
        data.customer_no = this.fastTrxCustomerNo;
      }
      this.dataBuyPrepaid = data;
    },
    startTrxPrepaid(dataStartTrxPrepaid) {
      const groupBy = (array, key) => {
        return array.reduce((result, currentValue) => {
          (result[currentValue[key]] = result[currentValue[key]] || []).push(
            currentValue
          );
          return result;
        }, {});
      };
      this.dataStartTrxPrepaid = groupBy(dataStartTrxPrepaid, "brand");
      this.startTrxPrepaidFInalData(
        this.dataStartTrxPrepaid[Object.keys(this.dataStartTrxPrepaid)[0]]
      );
    },
    startTrxPrepaidFInalData(trxPrepaidFInalData) {
      this.loadItemPrepaid(trxPrepaidFInalData);
      this.trxPrepaidFInalData = trxPrepaidFInalData;
      this.trxPrepaidFInalDataSelected = trxPrepaidFInalData[0];
    },
    async brandByNumber() {
      let customer_no = this.fastTrxCustomerNo;
      if (customer_no.substring(0, 2) == "62") {
        customer_no = 0 + customer_no.substring(2);
      }

      const prefTelkomsel = [
        "0811",
        "0812",
        "0813",
        "0821",
        "0822",
        "0823",
        "0851",
        "0852",
        "0853",
      ];
      const prefIndosat = [
        "0814",
        "0815",
        "0816",
        "0855",
        "0856",
        "0857",
        "0858",
      ];
      const prefXL = ["0817", "0818", "0819", "0859", "0877", "0878"];
      const prefAxis = ["0831", "0832", "0833", "0838"];
      const prefTri = ["0895", "0896", "0897", "0898", "0899"];
      const prefSmart = [
        "0881",
        "0882",
        "0883",
        "0884",
        "0885",
        "0886",
        "0887",
        "0888",
        "0889",
      ];
      const prefCeria = ["0828"];

      let prefixCustNo = customer_no.substring(0, 4);
      // if prefixCustNo in prefTelkomsel
      if (prefTelkomsel.includes(prefixCustNo)) {
        this.fastTrxBrandName = "telkomsel";
      } else if (prefIndosat.includes(prefixCustNo)) {
        this.fastTrxBrandName = "indosat";
      } else if (prefXL.includes(prefixCustNo)) {
        this.fastTrxBrandName = "xl";
      } else if (prefAxis.includes(prefixCustNo)) {
        this.fastTrxBrandName = "axis";
      } else if (prefTri.includes(prefixCustNo)) {
        this.fastTrxBrandName = "tri";
      } else if (prefSmart.includes(prefixCustNo)) {
        this.fastTrxBrandName = "smart";
      } else if (prefCeria.includes(prefixCustNo)) {
        this.fastTrxBrandName = "ceria";
      } else {
        this.fastTrxBrandName = "";
      }

      await this.loadPrepaid();
    },
  },

  watch: {
    fastTrxCustomerNo: function () {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        if (this.fastTrxCustomerNo.length > 3) {
          this.brandByNumber();
        } else if (this.fastTrxCustomerNo == "") {
          this.brandByNumber();
        }
      }, 800);
    },
  },
  created() {
    this.loadPrepaid();
  },
};
</script>
